<template>
  <v-container id="farmaco-info" fluid>
    <!-- TÍTULO  -->
    <v-row
      justify="center"
      class="sticky background-gradient top-title z-index-99"
    >
      <v-col cols="12" md="8" align-self="center">
        <page-top-title>
          <template v-slot:toptitle-icon>mdi-pill</template>
          <template v-slot:toptitle-text> {{ drugData.name }} </template>
        </page-top-title>
      </v-col>
      <v-col cols="12" md="2" align-self="center">
        <v-btn
          color="primary"
          :disabled="overlay"
          :loading="overlay"
          class="mt-8"
          :href="`https://mylogygenomics.com/?p=${drugData.wp_id}`"
        >
          <v-icon class="mt-n1 ml-n2 mr-2">mdi-currency-usd</v-icon>
          {{ $t("buy_pdf") }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="2" align-self="center">
        <customer-portal-button v-if="hide" />
      </v-col>
    </v-row>

    <!-- Mobile index indice movil -->
    <v-bottom-sheet
      v-model="sheet"
      inset
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="accent" dark v-bind="attrs" v-on="on" fab id="index-button" class="d-md-none">
          <v-icon color="primary">mdi-format-list-bulleted-type</v-icon>
        </v-btn>
      </template>
      <v-sheet
        class="text-center"
        height="100%"
      >
        <v-btn class="mt-6"
          text
          color="error"
          @click="sheet = !sheet"
        >
          {{ $t("Cerrar") }}
        </v-btn>
         <v-list dense nav class="pb-12">
              <scrollactive
                active-class="active"
                :offset="80"
                :modifyUrl="false"
                :scrollOffset="100"
                :duration="800"
                bezier-easing-value=".5,0,.35,1"
                class="my-nav"
              >
                <v-list-item
                  v-for="(section, index) in sectionsWithContent"
                  :key="section"
                  class="ml-2 scrollactive-item"
                  link
                  :href="'#' + section"
                >
                  <a class="scrollactive-item"
                    >{{ index + 1 }}. {{ $t("wgpgx_" + section, "en") }}</a
                  >
                </v-list-item>
              </scrollactive>
            </v-list>
      </v-sheet>
    </v-bottom-sheet>

    <v-row justify="center" v-if="drugData.id != null">
      <v-col cols="12" md="7">
        <v-content class="fill-height gradient-bucle">
          <section
            v-for="(section, index) in sectionsWithContent"
            :key="section"
            :id="section"
          >
            <v-card class="pa-0" rounded="lg">
              <v-card-title class="solid-heading rounded-lg py-3">
                <div class="card-title white--text mb-n2">
                  {{ index + 1 }}. {{ $t("wgpgx_" + section, "en") }}
                </div>
              </v-card-title>
              <v-card-text>
                <vue-markdown
                  :class="{ 'hide-content': hide }"
                  :source="drugData[section]"
                  class="px-2  py-4"
                />
              </v-card-text>
            </v-card>
          </section>
        </v-content>
      </v-col>

      <!-- NAVEGADOR LATERAL -->
      <v-col cols="4" md="4" lg="3" class="d-none d-md-block">
        <v-card max-height="90vh" flat class="nav-card py-0" elevation="0">
          <v-navigation-drawer permanent width="100%">
            <v-list dense nav class="pb-12">
              <scrollactive
                active-class="active"
                :offset="300"
                :modifyUrl="false"
                :scrollOffset="0"
                :duration="800"
                bezier-easing-value=".5,0,.35,1"
                class="my-nav"
              >
                <v-list-item
                  v-for="(section, index) in sectionsWithContent"
                  :key="section"
                  class="ml-2 scrollactive-item"
                  link
                  :href="'#' + section"
                >
                  <a class="scrollactive-item"
                    >{{ index + 1 }}. {{ $t("wgpgx_" + section, "en") }}</a
                  >
                </v-list-item>
              </scrollactive>
            </v-list>
          </v-navigation-drawer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from "@/services/Strapi.js";
import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";
import VueMarkdown from "vue-markdown-render";
import CustomerPortalButton from "@/components/mylogy/CustomerPortalButton.vue";
export default {
  name: "ExplorerDrugDetail",
  props: ["id"],
  components: {
    PageTopTitle,
    VueMarkdown,
    CustomerPortalButton
  },
  data() {
    return {
      sheet: false,
      drugSections: [
        "brand_names",
        "drug_combinations",
        "chemistry",
        "pharmacologic_category",
        "mechanism_of_action",
        "therapeutic_use",
        "pregnancy_and_lactation_implications",
        "unlabeled_use",
        "contraindications",
        "warnings_and_precautions",
        "adverse_reactions",
        "toxicological_effects",
        "caution",
        "other_genes_that_may_be_involved",
        "substrate_of",
        "induces",
        "inhibits",
        "drug_interactions",
        "dosage",
        "pharmacokinetics_and_pharmacodynamics",
        "special_considerations",
        "cns_effects",
        "genes_that_may_be_involved",
        "nutrition_nutraceutical_interactions"
      ],
      hide: true,
      drugData: {
        wp_id: null,
        has_atc: null,
        has_cas: null,
        _id: null,
        name: null,
        sku: null,
        brand_names: null,
        chemistry: null,
        pharmacologic_category: null,
        therapeutic_use: null,
        unlabeled_use: null,
        pregnancy_and_lactation_implications: null,
        contraindications: null,
        warnings_and_precautions: null,
        caution: null,
        substrate_of: null,
        inhibits: null,
        dosage: null,
        toc: null,
        createdAt: null,
        updatedAt: null,
        __v: null,
        max_atc_lvl: null,
        atc_codes: null,
        cas_codes: null,
        pharm_gkb_id: null,
        matched: null,
        indexed: null,
        euroidx: null,
        drug_central: null,
        kb_drug: null,
        id: null
      }
    };
  },
  computed: {
    sectionsWithContent() {
      let self = this;
      return self.drugSections.filter(ds => {
        return self.drugData[ds] != null;
      });
    }
  },
  mounted() {
    this.getEPGDrugData();
  },
  methods: {
    getEPGDrugData() {
      let self = this;
      Api.get(`drugs/${this.id}/kb`).then(function(resp) {
        self.hide = resp.data.hide;
        self.drugData = resp.data.epg;
      });
    }
  }
};
</script>

<style scoped>
.v-card__title {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

#index-button {
  position: fixed;
  bottom: 40px;
  right: 10px;
  z-index: 3;
}
</style>
<style lang="scss">
.v-card__text img {
  max-width: 100% !important;
}
</style>
